<script>
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const _t = getRootPluaralTranslator()
export const UCEscalationLevelOptions = () => [
  {
    id: 'level_1_violated',
    key: 'level_1_violated',
    text: `${_t('level')} 1`,
  },
  {
    id: 'level_2_violated',
    key: 'level_2_violated',
    text: `${_t('level')} 2`,
  },
  {
    id: 'level_3_violated',
    key: 'level_3_violated',
    text: `${_t('level')} 3`,
  },
  {
    id: 'level_4_violated',
    key: 'level_4_violated',
    text: `${_t('level')} 4`,
  },
  {
    id: 'level_5_violated',
    key: 'level_5_violated',
    text: `${_t('level')} 5`,
  },
]

export default {
  name: 'FlotoUCResponseEscalationLevelPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(UCEscalationLevelOptions())
    },
  },
}
</script>
