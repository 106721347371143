<template>
  <MRadioGroup
    v-model="currentType"
    as-button
    :options="currentOptions"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'ExportFormat',
  model: { event: 'change' },
  props: {
    value: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: undefined },
  },
  data() {
    this.currentOptions = this.options
      ? this.options
      : [
          { text: this.$t('pdf'), value: 'pdf' },
          { text: this.$t('excel'), value: 'excel' },
        ]
    return {}
  },
  computed: {
    currentType: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
