<script>
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getCredentialProfileApi } from '@/src/modules/asset-management/api/discovery-agents-api'
import omit from 'lodash/omit'

export default {
  name: 'CredentialProfilePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    allowOnlyEnabledOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadedCredentialProfile: [],
    }
  },
  computed: {
    options() {
      const { loadedCredentialProfile, allowOnlyEnabledOption } = this

      // If allowOnlyEnabledOption is true, return the original loadedCredentialProfile without any modifications
      if (allowOnlyEnabledOption) {
        return loadedCredentialProfile
      }

      // Otherwise, map through loadedCredentialProfile and omit the 'disabled' property from each item
      return loadedCredentialProfile.map((item) => omit(item, 'disabled'))
    },
  },
  created() {
    getCredentialProfileApi({}).then(
      ({ items }) =>
        (this.loadedCredentialProfile = items.map((t) => ({
          ...t,
          text: t.text || t.name,
          key: t.id,
        })))
    )
  },
}
</script>
