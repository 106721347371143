<template>
  <MRadioGroup
    :value="value"
    :options="scopeOptions()"
    :disabled="disabled"
    @change="$emit('change', $event)"
  />
</template>
<script>
export default {
  name: 'ScopeRadioGroup',
  model: { event: 'change' },
  props: {
    value: { type: String, default: 'global_access' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.scopeOptions = () => [
      { text: this.$tc('global_access'), value: 'global_access' },
      { text: this.$tc('group_access'), value: 'group_access' },
      { text: this.$tc('restricted'), value: 'restricted' },
      { text: this.$tc('global_restricted'), value: 'global_restricted' },
    ]
    return {}
  },
}
</script>
